import FormReferences from '@/app/Survey/model/FormReferences';
import FormStateResponse from '@/dtos/FormStateResponse';
import { LIMIT_AUTOCOMPLETE_DEFAULT } from '@/helpers/Constants';
import BaseService from '@/services/BaseService';
import { FormAutoCompleteData, FormStructure, IQuestions, IResponseAnswer, PartialAnswersToRemove } from '@/services/FormStructure';
import axios, { CancelTokenSource } from 'axios';

export default class FormService extends BaseService {
    private cancelSaveAnswerRequest: CancelTokenSource;

    public async validateFormAvailability(params: FormReferences): Promise<FormStateResponse> {
        return await this._get<FormStateResponse>(`forms/availability/`, params);
    }

    public async sendFormForComplete(params: FormReferences): Promise<FormComplete> {
        return await this._post(`forms/complete/`, params);
    }

    public async getFormById(id: number, transactionId?: number): Promise<FormStructure> {
        return await this._get<FormStructure>(`forms/${id}`, { transactionId });
    }

    public async saveAnswer(answers: IQuestions): Promise<IResponseAnswer[]> {
        const response = await this._post(`forms/answers/`, answers);
        return response as IResponseAnswer[];
    }

    public async cancelableSaveAnswer(answers: IQuestions): Promise<IResponseAnswer[]> {
        this.cancelSaveAnswerRequest = axios.CancelToken.source();
        const response = await this._post(`forms/answers/`, answers, this.cancelSaveAnswerRequest.token);
        return response as IResponseAnswer[];
    }

    /**
     * Cancels all previous ongoing service save answers fetch
     */
    public cancelSaveAnswer(): void {
        this.cancelSaveAnswerRequest?.cancel('Canceled');
    }

    public async deletePartialAnswers(answers: PartialAnswersToRemove): Promise<IResponseAnswer[]> {
        const response = await this._del(`forms/answers/partial`, answers);
        return response as IResponseAnswer[];
    }

    public async getAutoCompleteData(formId: number, questionId: number, searchString: string): Promise<FormAutoCompleteData[]> {
        try {
            let url = `forms/${formId}/questions/${questionId}/autocomplete-data?limit=${LIMIT_AUTOCOMPLETE_DEFAULT}`;
            if (searchString.length > 0) {
                url += `&search=${searchString}`;
            }
            const response = await this._get<FormAutoCompleteData[]>(decodeURIComponent(url));
            return response;
        } catch (error) {
            console.error("It's not possible load AutoComplete options", error);
            return [];
        }
    }
}
export interface FormComplete {
    id: number;
    formId: number;
    transactionId: number;
    completedAt: Date;
}
