import { Route } from 'vue-router/types/router';
import FormReferences from './FormReferences';
import TransactionReferences from './TransactionReferences';

export default class DataUrl {
    public readonly formId: number;
    public readonly apiKey: string;
    public readonly attemptId: number;
    public readonly lang: string;
    public readonly score: number;
    public readonly environment: string;
    public readonly mode: 'widget';

    public readonly transactionId: number;
    public readonly externalTransactionId: string;
    public readonly transactionJourney: string;
    public readonly transactionAmount: number;

    public readonly customerId: number;
    public readonly customerExternalId: string;
    public readonly customerName: string;
    public readonly customerEmail: string;
    public readonly customerPhone: string;
    public readonly customerPhone2: string;
    public readonly customerCpf: string;
    public readonly customerGender: string;
    public readonly customerBirthDate: string;

    public readonly employeeId: number;
    public readonly employeeExternalId: string;
    public readonly employeeName: string;

    public readonly storeId: number;
    public readonly storeExternalId: string;
    public readonly storeName: string;

    public get query(): Query {
        return {
            storeId: this.storeId,
            employeeId: this.employeeId,
            customerId: this.customerId,
            name: this.customerName,
            email: this.customerEmail,
            phone: this.customerPhone,
            phone2: this.customerPhone2,
            cpf: this.customerCpf,
            gender: this.customerGender,
            birthDate: this.customerBirthDate,
            journey: this.transactionJourney,
            lang: this.lang,
            amount: this.transactionAmount,
            attemptId: this.attemptId,
            transactionId: this.transactionId,
            environment: this.environment,
            mode: this.mode,
        };
    }

    constructor(route: Route) {
        const query = route.query;
        const params = route.params;

        this.apiKey = params.apiKey;
        this.formId = this.parseInt(params.formId);

        this.attemptId = this.parseInt(query.attemptId);
        this.transactionId = this.parseInt(query.transactionId);
        this.externalTransactionId = this.parseStr(query.externalTransactionId ?? query.transactionExternalId);
        this.transactionJourney = this.parseStr(query.transactionJourney);
        this.transactionAmount = this.parseNum(query.transactionAmount);
        this.environment = this.parseStr(query.environment);
        this.mode = this.parseMode(query.mode);

        this.customerId = this.parseInt(query.customerId);
        this.customerExternalId = this.parseStr(query.customerExternalId);
        this.customerName = this.parseStr(query.customerName);
        this.customerEmail = this.parseStr(query.customerEmail);
        this.customerPhone = this.parseStr(query.customerPhone);
        this.customerPhone2 = this.parseStr(query.customerPhone2);
        this.customerCpf = this.parseStr(query.customerCpf);
        this.customerGender = this.parseStr(query.customerGender);
        this.customerBirthDate = this.parseStr(query.customerBirthDate);

        this.employeeId = this.parseInt(query.employeeId);
        this.employeeExternalId = this.parseStr(query.employeeExternalId);
        this.employeeName = this.parseStr(query.employeeName);

        this.storeId = this.parseInt(query.storeId);
        this.storeExternalId = this.parseStr(query.storeExternalId);
        this.storeName = this.parseStr(query.storeName);

        this.lang = this.parseStr(query.lang);
        this.score = this.parseInt(query.score) ?? this.parseInt(query.rating);
    }

    public get isUsingExternalReference() {
        return this.transactionReferenceKey === 'externalTransactionId';
    }

    public get transactionReference(): number | string {
        return this.transactionId || this.externalTransactionId;
    }

    public get formCompleteSet(): FormReferences {
        return { transactionId: this.transactionId, formId: this.formId, attemptId: this.attemptId };
    }

    public get formValidationSet(): FormReferences {
        const transactionReference: TransactionReferences = { [this.transactionReferenceKey]: this.transactionReference };
        const references = { ...transactionReference, formId: this.formId };

        if (!this.isUsingExternalReference) {
            references['attemptId'] = this.attemptId;
        }

        return references;
    }

    private get transactionReferenceKey(): string {
        return this.transactionId ? 'transactionId' : 'externalTransactionId';
    }

    private parseInt(value: string | string[]): number {
        return value != null ? parseInt(value as string) : null;
    }

    private parseMode(value: string | string[]): 'widget' {
        return value === 'widget' ? 'widget' : null;
    }

    private parseNum(value: string | string[]): number {
        return value != null ? Number(value) : null;
    }

    private parseStr(value: string | string[]): string {
        return value != null ? String(value) : null;
    }
}
interface Query {
    storeId?: number;
    employeeId?: number;
    customerId?: number;
    name?: string;
    email?: string;
    phone?: string;
    phone2?: string;
    cpf?: string;
    gender?: string;
    birthDate?: string;
    journey?: string;
    lang?: string;
    amount?: number;
    attemptId?: number;
    transactionId?: number;
    score?: number;
    environment?: string;
    mode?: 'widget';
}
